import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from 'react-bootstrap/Button';

import '../Pack.scss';

type Props = {
  pack: Object,
  isFollowed: boolean,
  onMarkAsRead: Function,
};

/**
 * PACK TEASER
 */
const PackContent = ({
  pack: { description, unreadCount, files, isNew },
  // isFollowed,
  onMarkAsRead,
}: Props) => {
  const { t } = useTranslation('folder');
  const descriptionUpdated = description || t('global:default-description');
  const hasUnread = isNew || unreadCount > 0;
  return (
    <>
      <div className="Pack-mainContent">
        <div className="Pack-separatorZone">
          <div className="Pack-separator" />
        </div>
        <div className="Pack-content">
          <div className="Pack-description">
            <p className="flowfont-regular">{descriptionUpdated}</p>
          </div>
        </div>
      </div>
      {files && files.length === 0 && <p className="Pack-waiting fs-09">{t('global:no-content')}</p>}
      {!files && <p className="Pack-waiting fs-09">{t('global:waiting-content')}</p>}
      {hasUnread && (
        <Button variant="link" className="ml-auto d-block flowfont-medium" onClick={onMarkAsRead}>
          {t('mark-all-as-read')}
        </Button>
      )}
    </>
  );
};

export default React.memo(PackContent);
